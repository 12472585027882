import './Mensaje.css';

const Mensaje = () => {
  return (
    <div style={{background: "#cdcab614", display: "flex", userSelect: "none"}}>
      <div className="mensaje">
        <span>"Señor, ayudanos a recordar el día en que nos conocimos y el fuerte amor que creció entre nosotros.</span>
        <span>Ayudanos a usar nuestro amor para cosas que nos hagan crecer y que nada pueda dividirnos.</span>
        <span>Te pedimos que siempre tengamos palabras de amor y que nuestro corazon siempre este dispuesto a perdonar.</span>
        <span>Señor, ponemos nuestro matrimonio en tus manos."</span>
        <span>Amén.</span>
      </div>
    </div>
  );
};

export default Mensaje;