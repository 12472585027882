import firebase, { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { addDoc, collection, getFirestore, setDoc, doc, getDoc, getDocs, onSnapshot, deleteDoc, query, where } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC1DmsN1kCsYPROcwNT4sXHildqvTswBug",
  authDomain: "wedding-app-2801.firebaseapp.com",
  projectId: "wedding-app-2801",
  storageBucket: "wedding-app-2801.appspot.com",
  messagingSenderId: "105977679485",
  appId: "1:105977679485:web:0c8157b7a7c6d810156b14",
  measurementId: "G-8YRRX766QX"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)
const firestore = getFirestore(app);
const analytics = getAnalytics(app);

export { app, auth, firestore, addDoc, setDoc, doc, getDoc, deleteDoc, getDocs, collection, query, where, analytics, onSnapshot };

// export multiple functions
// export { addInvitado, InvitadoCollection, InvitadoModel }