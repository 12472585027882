import { removeAccents } from "../utils/shared";
import { firestore, addDoc, collection, doc, setDoc, getDocs, deleteDoc, onSnapshot } from "./firebase.config";
import dayjs, { Dayjs } from "dayjs";

export const invitadosCollection = collection(firestore, 'invitados');

export { onSnapshot }

export type InvitadoCollection = {
    key?: string;
    nombre: string;
    genero?: string;
    fechaConfirmacion: Date | string;
    estado: string;
};

export type InvitadoModel = {
    key?: string;
    nombre: string;
    fechaConfirmacionDayjs: Dayjs;
    estado: string;
};

export const addInvitado = async (invitado: InvitadoCollection) => {
    try {
        delete invitado.key; 
        const documentId = removeAccents(invitado.nombre.toLowerCase().split(" ").join("_"));
        const ref = doc(invitadosCollection, documentId);
        await setDoc(ref, invitado);

        localStorage.removeItem('invitados');
    } catch (e) {
        console.error("Error adding document: ", e);
    }
}

export const updateInvitado = async (invitado: InvitadoCollection) => {
    try {
        const documentId = invitado.key;
        delete invitado.key;
        const ref = doc(invitadosCollection, documentId);
        await setDoc(ref, invitado);
    } catch (e) {
        console.error("Error editing document: ", e);
    }
}

export const deleteInvitado = async (key: string) => {
    try {
        const documentId = key;
        const ref = doc(invitadosCollection, documentId);
        await deleteDoc(ref);

        localStorage.removeItem('invitados');
    } catch (e) {
        console.error("Error deleting document: ", e);
    }
}

export const getInvitados = () => {
    if (localStorage.getItem("invitados") === null) {
        onSnapshot(invitadosCollection, (querySnapshot) => {
            const invitados: any = [];
            querySnapshot.forEach((doc: any) => {
                invitados.push({
                    key: doc.id,
                    nombre: doc.data().nombre,
                    fechaConfirmacion: doc.data().fechaConfirmacion ? dayjs(doc.data().fechaConfirmacion.toDate()).format('DD/MM/YYYY') : '',
                    estado: doc.data().estado
                });
            });
            localStorage.setItem('invitados', JSON.stringify(invitados));
        });
    }
}

export const getInvitadosLocal = () => {
    const invitados: InvitadoCollection[] = JSON.parse(localStorage.getItem('invitados') || '[]');
    if (invitados.length > 0) {
        return invitados;
    }
    return [];
}

export const guardarInvitadosLocal = async () => {
    // const querySnapshot = await getDocs(invitadosCollection);
    // const invitados: InvitadoCollection[] = [];
    // querySnapshot.forEach((doc:any) => {
    //     debugger;
    //     const invitado = doc.data() as InvitadoCollection;
    //     invitados.push({
    //         key: new Date().getTime().toString(),//doc.id,
    //         nombre: invitado.nombre,
    //         fechaConfirmacion: '',//doc.data().fechaConfirmacion ? dayjs(doc.data().fechaConfirmacion.toDate()).format('DD/MM/YYYY') : '',
    //         confirmado: '',//doc.data().confirmado ? 'Si' : 'No'
    //     });
    // });
    onSnapshot(invitadosCollection, (querySnapshot) => {
        const invitados: any = [];
        querySnapshot.forEach((doc: any) => {
            invitados.push({
                key: doc.id,
                nombre: doc.data().nombre,
                fechaConfirmacion: doc.data().fechaConfirmacion ? dayjs(doc.data().fechaConfirmacion.toDate()).format('DD/MM/YYYY') : '',
                estado: doc.data().estado
            });
        });
        localStorage.setItem('invitados', JSON.stringify(invitados));
    });

}

// export const saveLocalInvitados = async () => {

//     onSnapshot(invitadosCollection, (querySnapshot) => {
//         const items:InvitadoCollection[] = [];
//         querySnapshot.forEach((doc: any) => {
//             items.push(doc.data() as InvitadoCollection);
//         });

//         localStorage.setItem('invitados', JSON.stringify(items)); 
//     });


//      // const querySnapshot = await firestore.collection("invitados").get();
//     // const invitados: InvitadoModel[] = [];
//     // querySnapshot.forEach((doc:any) => {
//     //     const invitado = doc.data() as InvitadoCollection;
//     //     invitados.push({
//     //         nombre: invitado.nombre,
//     //         fechaConfirmacionDayjs: dayjs(invitado.fechaConfirmacion),
//     //         confirmado: invitado.confirmado
//     //     });
//     // });
//     // return invitados;
// }


