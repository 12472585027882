const ALPHABET = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
const KEY =      "PHQGIUMEAYLNOFDXJKRCVSTZWB6908745312";

const encrypt = (text: string): string => {
  let encryptedText = "";
  for (let i = 0; i < text.length; i++) {
    const charIndex = ALPHABET.indexOf(text[i]);
    if (charIndex === -1) {
      encryptedText += text[i];
    } else {
      encryptedText += KEY[charIndex];
    }
  }
  return encryptedText;
}

const decrypt = (text: string): string => {
  let decryptedText = "";
  for (let i = 0; i < text.length; i++) {
    const charIndex = KEY.indexOf(text[i]);
    if (charIndex === -1) {
      decryptedText += text[i];
    } else {
      decryptedText += ALPHABET[charIndex];
    }
  }
  return decryptedText;
}

export { encrypt, decrypt }