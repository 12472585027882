import { useCallback, useEffect, useState } from "react";
import { EditFilled, DeleteFilled, GlobalOutlined, LinkOutlined } from '@ant-design/icons';
import { Alert, Button, DatePicker, Form, Input, Radio, Select, SelectProps, Space, Switch, Table, TableColumnsType, Tooltip } from 'antd';
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { onSnapshot, InvitacionCollection, getInvitaciones, getInvitacionesLocal, addInvitacion, deleteInvitacion, updateInvitacion, InvitadoModel, invitacionesCollection } from "../../firebase/invitaciones.controller";
import './Invitaciones.css';
import { ColumnsType } from "antd/es/table";
import { getInvitados, getInvitadosLocal, invitadosCollection } from "../../firebase/invitados.controller";
import { encrypt } from "../../utils/security";
import { removeAccents } from "../../utils/shared";
import { deleteDoc, doc } from "firebase/firestore";
import { stringify } from "querystring";
import { useNavigate, useParams } from "react-router-dom";

require('dayjs/locale/es')
dayjs().locale('es').format()
dayjs.extend(localizedFormat);

const Invitaciones = () => {
  const { code } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [invitaciones, setInvitaciones] = useState<InvitacionCollection[]>([]);
  const [options, setOptions] = useState<SelectProps['options']>([]);
  let isEditing = false;

  const handleDelete = async (key: string) => {
    const documentId = key;
    const ref = doc(invitacionesCollection, documentId);
    await deleteDoc(ref);
    // deleteInvitacion(key);
    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  }

  const handleEdit = (record: any) => {
    isEditing = true;

    const optiones: SelectProps['options'] = [];
    record.miembros.forEach((invitacion: any) => {
      optiones.push({ label: invitacion.nombre, key: invitacion.key, value: invitacion });
    });

    form.setFieldsValue({
      key: record.key,
      nombre: record.nombre,
      genero: record.genero,
      miembros: optiones,
      code: record.code
    });

  }

  const handleInvitacionLink = (code: string) => {
    const encryp = encrypt(code);
    const link = `${window.location.origin}?code=${encryp}`;
    navigator.clipboard.writeText(link)
    //link open in new tab
    window.open(link, '_blank');
  }

  const columns: ColumnsType<InvitacionCollection> = [
    {
      title: '', key: 'edit',
      render: (_: any, record: any) => (
        <Button icon={<EditFilled />} onClick={() => handleEdit(record)} size="small"></Button>
      )
    },
    {
      title: 'Nombre', dataIndex: 'nombre', key: 'nombre',
      render: (nombre: string, record: any) => (
        <Tooltip title={record.miembros.map((m: any) => `${m.nombre} (${m.estado.charAt(0)})`).join(", ")}>
          <span>{nombre} ({record.miembros.length}) ({record.genero})</span>
        </Tooltip>
      )
    },
    { title: 'Code', dataIndex: 'code', key: 'code' },
    {
      title: '', key: 'link',
      render: (_: any, record: any) => (
        <>
          <Button icon={<LinkOutlined />} onClick={() => { handleInvitacionLink(record.code) }} size="small"></Button>
        </>
      )
    },
    // {
    //   title: '', key: 'delete',
    //   render: (_: any, record: any) => (
    //     <Button icon={<DeleteFilled />} onClick={() => handleDelete(record.key)} size="small"></Button>
    //   )
    // }
  ];

  useEffect(() => {
    if (code == undefined || code != "June172801.") {
      navigate(`/`);
    }

    onSnapshot(invitadosCollection, (querySnapshot) => {
      const invitados: any = [];
      querySnapshot.forEach((doc: any) => {
        invitados.push({
          key: doc.id,
          nombre: doc.data().nombre,
          fechaConfirmacion: doc.data().fechaConfirmacion ? dayjs(doc.data().fechaConfirmacion.toDate()).format('DD/MM/YYYY') : '',
          estado: doc.data().estado
        });
      });

      if (options?.length == 0) {
        const selectOptions: SelectProps['options'] = [];
        invitados.forEach((invitado: any) => {
          selectOptions.push({ label: invitado.nombre, key: invitado.key, value: invitado.key });
        });
        setOptions(selectOptions);
      }
    });

    onSnapshot(invitacionesCollection, (querySnapshot) => {
      const invitaciones: any = [];
      querySnapshot.forEach((doc: any) => {
        invitaciones.push({
          key: doc.id,
          nombre: doc.data().nombre,
          genero: doc.data().genero,
          code: doc.data().code,
          miembros: doc.data().miembros
        });
      });


      setInvitaciones(invitaciones);
    });

    // getInvitados();
    // getInvitaciones();
    // setTimeout(() => {
    //   const invitaciones = getInvitacionesLocal();
    //   setInvitaciones(invitaciones);

    //   const invitados = getInvitadosLocal();
    //   if (options?.length == 0) {

    //     const selectOptions: SelectProps['options'] = [];
    //     invitados.forEach((invitado) => {
    //       selectOptions.push({ label: invitado.key, value: invitado.key });
    //     });
    //     setOptions(selectOptions);
    //   }
    // }, 1000);

  }, []);

  const handleCancelar = () => {
    form.resetFields();
    isEditing = false;
  }

  const onFinish = (formValue: InvitacionCollection) => {
    const miembros: InvitadoModel[] = [];

    formValue.miembros.forEach((miembro: any) => {
      miembros.push({ key: (typeof miembro.value === "object") ? miembro.value.key : miembro.key, nombre: miembro.label, estado: (typeof miembro.value === "object") ? miembro.value.estado : 'PENDIENTE', fechaConfirmacion: (miembro.value.fechaConfirmacion) ? miembro.value.fechaConfirmacion : dayjs('2023-01-01', 'YYYY-MM-DD').toDate() });
    });

    const invitacion: InvitacionCollection = {
      key: formValue.key,
      nombre: formValue.nombre,
      genero: formValue.genero,
      miembros: miembros,
      code: formValue.code,
    };

    if (isEditing) {
      // invitacion.miembros.forEach((miembro: any) => {
      //   miembro.estado = 'PENDIENTE';
      // });
      updateInvitacion(invitacion);
      isEditing = false;
    }
    else {
      invitacion.miembros.forEach((miembro: any) => {
        miembro.estado = 'PENDIENTE';
      });
      addInvitacion(invitacion);
    }

    form.resetFields();

    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  };

  return (
    <>
      <div className="crear-invitacion">
        <label className="titulo">Agregar Invitaciones</label>
        <Form
          form={form}
          onFinish={onFinish}
          labelCol={{ span: 24 }}
          wrapperCol={{ span: 16 }}
          layout="vertical"
          style={{ maxWidth: 350, margin: 'auto', paddingLeft: 10, paddingRight: 10 }}
        >
          <Form.Item name="key" hidden={true}>
            <Input />
          </Form.Item>
          <Form.Item label="Nombre" name="nombre" wrapperCol={{ span: 24 }} required={true}>
            <Input />
          </Form.Item>
          <Form.Item label="Code" name="code" wrapperCol={{ span: 24 }} required={true}>
            <Input />
          </Form.Item>
          <Form.Item label="Género" name="genero" wrapperCol={{ span: 24 }} required={true}>
            <Radio.Group size="small">
              <Radio.Button value="M">Mujer</Radio.Button>
              <Radio.Button value="H">Hombre</Radio.Button>
              <Radio.Button value="F">Familia</Radio.Button>
              <Radio.Button value="G">Grupo Génerico</Radio.Button>
              <Radio.Button value="GM">Grupo Mujeres</Radio.Button>
            </Radio.Group>
          </Form.Item>

          <Form.Item label="Miembros" name="miembros" wrapperCol={{ span: 24 }}>
            <Select
              mode="tags"
              allowClear
              style={{ width: '100%' }}
              placeholder="Selecciona los miembros"
              tokenSeparators={[',']}
              options={options}
              labelInValue={true}
              showSearch={false}
            />
          </Form.Item>
          <Form.Item wrapperCol={{ span: 24 }}>
            <Space className="botones">
              <Button type="primary" onClick={handleCancelar} style={{ flex: 1, width: "100%" }}>
                Cancelar
              </Button>
              <Button type="primary" htmlType="submit" style={{ flex: 1, width: "100%" }}>
                Guardar
              </Button>
            </Space>
          </Form.Item>
        </Form>
        <label className="titulo">Lista de Invitaciones ({invitaciones.length})</label>
        <div className="tabla">
          <Table showHeader={false} columns={columns} dataSource={invitaciones} pagination={false} size="small" />
        </div>
      </div>
    </>
  );
};

export default Invitaciones;
