const formatTime = (endDate:string) => {
  let timeLeft = Math.floor((new Date(endDate).getTime() - new Date().getTime()) / 1000);

  let days = Math.floor(timeLeft / 86400).toString();
  days = (+days < 10)? '0' + days: days;
  let hours = Math.floor((timeLeft % 86400) / 3600).toString();
  hours = (+hours < 10)? '0' + hours: hours;
  let minutes = Math.floor((timeLeft % 3600) / 60).toString();
  minutes = (+minutes < 10)? '0' + minutes: minutes;
  let seconds = (timeLeft % 60).toString();
  seconds = (+seconds < 10)? '0' + seconds: seconds;

  if (timeLeft < 0) {
    return { days: '00', hours: '00', minutes: '00', seconds: '00', timeLeft: 0 };
  } else {
    //two digits for each number
    return { days, hours, minutes, seconds, timeLeft };
  }
};

export default formatTime;