export const enum EstadoConfirmacion {
    Pendiente = 'PENDIENTE',
    SiAsistire = 'SÍ, ASISTIRÉ',
    NoPodreLoSiento = 'NO PODRÉ ASISTIR',
}

export const enum Seccion {
    Church = 'Church',
    Reception = 'Reception',
    Gift = 'Gift',
    Confirmation = 'Confirmation',
}

export const removeAccents = (string: string) => {
    return string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
} 