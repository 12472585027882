import { GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, signOut, onAuthStateChanged, signInWithRedirect } from "firebase/auth"
import { useState, createContext, useContext, useEffect } from 'react'
import { auth } from "../firebase/firebase.config"

export const authContext = createContext()

export const useAuth = () => {
    const context = useContext(authContext)
    if (!context)
        throw new Error('useAuth must be used within an AuthProvider')

    return context;
}

export function AuthProvider({ children }) {
    const [user, setUser] = useState(null);
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUser(user);
            } else {
                setUser(null);
            }
        })
        return () => unsubscribe();
    }, [])
    

    const login = async (email, password) => {
        const response = await signInWithEmailAndPassword(auth, email, password);
        return response;
    }

    const loginWithGoogle = async () => {
        //open google popup on mobile devices and web browser
        


        const responseGoogle = new GoogleAuthProvider();
        const response = await signInWithRedirect(auth, responseGoogle);
        return response;
    }

    const logout = async () => {
        return await signOut(auth);
    }

    return (
        <authContext.Provider value={{ login, loginWithGoogle, logout, user}}>
            {children}
        </authContext.Provider>
    )
}