import { removeAccents } from "../utils/shared";
import { firestore, addDoc, collection, doc, setDoc, getDocs, deleteDoc, onSnapshot, app, query, where } from "./firebase.config";
import dayjs, { Dayjs } from "dayjs";
export const invitacionesCollection = collection(firestore, 'invitaciones');
export { onSnapshot }

export type InvitadoModel = {
    key?: string | undefined;
    nombre: string;
    estado?: string;
    fechaConfirmacion?: Date | string;
}

const localStorageName = 'invitaciones';

export type InvitacionCollection = {
    key?: string;
    code: string;
    nombre: string;
    genero?: string;
    miembros: InvitadoModel[];
};

export const addInvitacion = async (invitacion: InvitacionCollection) => {
    try {
        delete invitacion.key;
        const documentId = removeAccents(invitacion.nombre.toLowerCase().trim().split(" ").join("_"));
        const ref = doc(invitacionesCollection, documentId);
        await setDoc(ref, invitacion);

        localStorage.removeItem(localStorageName);
    } catch (e) {
        console.error("Error adding invitacion: ", e);
    }
}

export const updateInvitacion = async (invitacion: InvitacionCollection) => {
    try {
        const documentId = invitacion.key;
        delete invitacion.key;
        const ref = doc(invitacionesCollection, documentId);
        await setDoc(ref, invitacion);
    } catch (e) {
        console.error("Error editing invitacion: ", e);
    }
}

export const deleteInvitacion = async (key: string) => {
    try {
        const documentId = key;
        const ref = doc(invitacionesCollection, documentId);
        await deleteDoc(ref);

        localStorage.removeItem(localStorageName);
    } catch (e) {
        console.error("Error deleting invitacion: ", e);
    }
}

export const getInvitaciones = () => {
    if (localStorage.getItem(localStorageName) === null) {
        onSnapshot(invitacionesCollection, (querySnapshot) => {
            const invitaciones: any = [];
            querySnapshot.forEach((doc: any) => {
                invitaciones.push({
                    key: doc.id,
                    nombre: doc.data().nombre,
                    genero: doc.data().genero,
                    code: doc.data().code,
                    miembros: doc.data().miembros
                });
            });
            localStorage.setItem(localStorageName, JSON.stringify(invitaciones));
        });
    }
}

export const getInvitacionByCode = async (code: string) => {
    const invitacionesRef = collection(firestore, 'invitaciones');
    const invitacionQuery = query(invitacionesRef, where("code", "==", code));
    const querySnapshot = await getDocs(invitacionQuery);

    if (querySnapshot.docs.length == 0) {
        return null;
    }

    let invitacion = {} as InvitacionCollection;
    querySnapshot.forEach((doc) => {
        invitacion = {
            key: doc.id,
            nombre: doc.data().nombre,
            genero: doc.data().genero,
            code: doc.data().code,
            miembros: doc.data().miembros
        };
    });

    return invitacion;
}

export const getInvitacionesLocal = () => {
    const invitaciones: InvitacionCollection[] = JSON.parse(localStorage.getItem(localStorageName) || '[]');
    if (invitaciones.length > 0) {
        return invitaciones;
    }
    return [];
}
