import { Button, Form, Input } from "antd";
import { useEffect, useState } from "react";
import { useAuth } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";

const Login = () => {
    const { code } = useParams();
    const navigate = useNavigate();
    const auth = useAuth();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const handleLogin = async (e: any) => {
        e.preventDefault();
        await auth.login(email, password);
    };

    useEffect(() => {
        if (code == undefined || code != "June172801.") {
            navigate(`/`);
        }
    }, []);

    const handleGoogleLogin = async (e: any) => {
        e.preventDefault();
        const response = await auth.loginWithGoogle();
        if (response.operationType == "signIn") {
            // navigate("/invitados");
        }
    };
    
    const handleLogout = (e: any) => {
        e.preventDefault();
        localStorage.clear();
        auth.logout();
    };

    return (
        <>
            {auth.user ? <div>Logged in</div> : <div>Logged out</div>}
            <label className="title">Login</label>
            <Input placeholder="Email" onChange={(e) => setEmail(e.target.value)} />
            <Input.Password placeholder="Password" onChange={(e) => setPassword(e.target.value)} />
            <Button type="primary" htmlType="submit" style={{ width: "100%" }} onClick={(e) => auth.login(email, password)}>
                Login
            </Button>

            <Button type="primary" htmlType="submit" style={{ width: "100%" }} onClick={(e) => handleGoogleLogin(e)}>
                Login Google
            </Button>
            <Button type="primary" htmlType="submit" style={{ width: "100%" }} onClick={(e) => handleLogout(e)}>
                Logout
            </Button>
            {/* <Form
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 16 }}
                layout="vertical"
                style={{ maxWidth: 350, margin: 'auto', paddingLeft: 10, paddingRight: 10 }}
            >
                <Form.Item label="Email" name="email" wrapperCol={{ span: 24 }} required={true}>
                    <Input />
                </Form.Item>
                <Form.Item label="Password" name="password" wrapperCol={{ span: 24 }} required={true}>
                    <Input.Password />
                </Form.Item>
                <Form.Item wrapperCol={{ span: 24 }}>
                    <Button type="primary" htmlType="submit" style={{ width: "100%" }}>
                        Login
                    </Button>
                </Form.Item>
            </Form> */}
        </>
    );
};

export default Login;