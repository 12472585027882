// import { CopyToClipboards } from "react-copy-to-clipboard";
import foto1 from '../../assets/fotos/foto-1.jpg';
import foto2 from '../../assets/fotos/foto-2.jpg';
import foto3 from '../../assets/fotos/foto-3.jpg';
import foto4 from '../../assets/fotos/foto-4.jpg';
import foto5 from '../../assets/fotos/foto-5.jpg';
import foto6 from '../../assets/fotos/foto-6.jpg';
import { useCallback, useEffect, useState } from "react";
import { ExclamationCircleOutlined, CloseOutlined, EditFilled, DeleteFilled, GlobalOutlined, FrownOutlined, CheckCircleOutlined, SmileOutlined, CloseCircleFilled } from '@ant-design/icons';
import { Alert, Button, DatePicker, Form, Input, Select, SelectProps, Space, Switch, Table, TableColumnsType, Tooltip, notification } from 'antd';
import dayjs from "dayjs";
import localizedFormat from 'dayjs/plugin/localizedFormat';
import { onSnapshot, InvitacionCollection, getInvitaciones, getInvitacionesLocal, addInvitacion, deleteInvitacion, updateInvitacion, InvitadoModel, invitacionesCollection } from "../../firebase/invitaciones.controller";
import './Invitacion.css';
import { ColumnsType } from "antd/es/table";
import { decrypt, encrypt } from "../../utils/security";
import { EstadoConfirmacion, Seccion } from "../../utils/shared";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { motion } from 'framer-motion';
import { InvitadoCollection, getInvitados, getInvitadosLocal, invitadosCollection, updateInvitado } from "../../firebase/invitados.controller";
import { collection, getDocs, query, where, setDoc } from '../../firebase/firebase.config';
import { firestore } from '../../firebase/firebase.config';
import { DocumentReference, Firestore, doc, updateDoc } from 'firebase/firestore';
import { NotificationPlacement } from 'antd/es/notification/interface';

require('dayjs/locale/es')
dayjs().locale('es').format()
dayjs.extend(localizedFormat);

type NotificationType = 'success' | 'info' | 'warning' | 'error';

enum Genero {
    Hombre = 'H',
    Mujer = 'M',
    Familia = 'F',
    GrupoGenerico = 'G',
    GrupoMujeres = 'GM'
}

const getMensajeBienvenida = (genero: Genero, invitacion: InvitacionCollection) => {
    let mensaje = '';
    const nombresMiembros = invitacion.miembros.map((miembro) => miembro.nombre.split(' ')[0]);
    const nombresString = nombresMiembros.join(', ');
    const nombresArray = nombresString.split(', ');
    const ultimoNombre = nombresArray.pop();
    const nombresStringFinal = nombresArray.join(', ') + ` y ${ultimoNombre}`;

    switch (genero) {
        case Genero.Hombre:
            mensaje = `Bienvenido ${nombresMiembros[0]}`;
            break;
        case Genero.Mujer:
            mensaje = `Bienvenida ${nombresMiembros[0]}`;
            break;
        case Genero.Familia:
            mensaje = `Bienvenidos ${invitacion.nombre}`;
            break;
        case Genero.GrupoGenerico:
            mensaje = `Bienvenidos ${nombresStringFinal}`;
            break;
        case Genero.GrupoMujeres:
            mensaje = `Bienvenidas ${nombresStringFinal}`;
            break;
        default:
            break;
    }
    return mensaje;
}

const Invitacion = () => {
    const navigate = useNavigate();
    const { code } = useParams();
    const location = useLocation();
    const [invitacion, setInvitacion] = useState<InvitacionCollection>();
    const [invitados, setInvitados] = useState<InvitadoCollection[]>([]);
    const [loadings, setLoadings] = useState<boolean[]>([]);

    const loading = (index: number, value: boolean) => {
        setLoadings((prevLoadings) => {
            const newLoadings = [...prevLoadings];
            newLoadings[index] = value;
            return newLoadings;
        });
    };

    const [api, contextHolder] = notification.useNotification();

    const openNotification = (placement: NotificationPlacement, type: NotificationType, icon: string, message: string, description: string = "") => {
        let customIcon = <></>;
        switch (type) {
            case 'success':
                customIcon = (icon === 'check') ? <CheckCircleOutlined style={{ color: '#918b65' }} /> : <SmileOutlined style={{ color: '#918b65' }} />;
                break;
            case 'info':
                customIcon = <GlobalOutlined style={{ color: '#108ee9' }} />;
                break;
            case 'warning':
                customIcon = <FrownOutlined style={{ color: '#faad14' }} />;
                break;
            case 'error':
                customIcon = <CloseCircleFilled style={{ color: '#f5222d' }} />;
                break;
            default:
                break;
        }

        api[type]({
            message: message,
            description: description,
            placement,
            duration: 5,
            icon: customIcon,
            closeIcon: <CloseOutlined style={{ fontSize: "16px" }} />,
            className: 'custom-notification',
        });
    };

    const handleConfirmacion = async (key: string, estado: EstadoConfirmacion, invitacion: any) => {
        loading(0, true);
        const invitado = invitados.find(x => x.key === key);
        invitado!.estado = estado;
        invitado!.fechaConfirmacion = dayjs(dayjs(), 'YYYY-MM-DD HH:mm:ss').toDate();
        invitacion.miembros = invitados;


        const invitadoDocumentId = invitado!.key;
        const invitadoRef = doc(invitadosCollection, invitadoDocumentId);
        await setDoc(invitadoRef, invitado);


        const invitacionDocumentId = invitacion.key;
        const invitacionRef = doc(invitacionesCollection, invitacionDocumentId);
        await setDoc(invitacionRef, invitacion);
        loading(0, false);
        openNotification('top', 'success', 'check', '¡Confirmación recibida!');
    }

    const columns: ColumnsType<InvitadoCollection> = [
        {
            title: 'Nombre', dataIndex: 'nombre', key: 'nombre',
            render: (_: any, record: any) => (
                <>
                    <span className="nombre-invitado">{record.nombre}</span>
                    {
                        record.estado === EstadoConfirmacion.Pendiente &&
                        <Space key={record.key} className="contenedor-confirmacion-button" >
                            <Button loading={loadings[0]} ghost className="confirmacion-button" onClick={() => handleConfirmacion(record.key, EstadoConfirmacion.SiAsistire, invitacion)}>Sí, asistiré</Button>
                            <Button loading={loadings[0]} ghost className="confirmacion-button" onClick={() => handleConfirmacion(record.key, EstadoConfirmacion.NoPodreLoSiento, invitacion)}>No podré asistir</Button>
                        </Space>
                    }
                    {
                        record.estado != EstadoConfirmacion.Pendiente &&
                        <span className="estado-invitado">{record.estado}</span>
                    }

                </>
            )
        },
    ];

    const [codeDecrypt, setCodeDecrypt] = useState('');

    const [notificationOpened, setNotificationOpened] = useState(false);

    useEffect(() => {
        if (location.state == undefined) {
            navigate(`/`);
        }

        if (location.state) {
            // const invitaciones: any = [];
            // const invitacion = ((location.state as any).invitacion as InvitacionCollection);
            // setInvitacion(invitacion);
            // setInvitados(invitacion.miembros as InvitadoCollection[]);
            // if (notificationOpened === false) {
            //     setNotificationOpened(true);
            //     openNotification('top', 'success', getMensajeBienvenida(invitacion?.genero as Genero, invitacion))
            // }




            const invitacion = ((location.state as any).invitacion as InvitacionCollection);
            const invitacionesRef = collection(firestore, 'invitaciones');
            const invitacionQuery = query(invitacionesRef, where("code", "==", invitacion.code));

            getDocs(invitacionQuery)
                .then((querySnapshot) => {
                    if (querySnapshot.docs.length == 0) {
                        return null;
                    }

                    let invitacion = {} as InvitacionCollection;
                    querySnapshot.forEach((doc) => {
                        invitacion = {
                            key: doc.id,
                            nombre: doc.data().nombre,
                            genero: doc.data().genero,
                            code: doc.data().code,
                            miembros: doc.data().miembros
                        };
                    });

                    setInvitacion(invitacion);
                    setInvitados(invitacion.miembros as InvitadoCollection[]);

                    if (notificationOpened === false) {
                        setNotificationOpened(true);
                        openNotification('top', 'success', 'smile', getMensajeBienvenida(invitacion?.genero as Genero, invitacion))
                    }
                });
        }



        // if (code && invitacion == undefined) {
        //     const fetchInvitacion = async () => {
        //         setCodeDecrypt(decrypt(code || ''));

        //         const invitacionesRef = collection(firestore, 'invitaciones');
        //         const invitacionQuery = query(invitacionesRef, where("code", "==", codeDecrypt));


        //         function sleep(ms: any) {
        //             return new Promise(resolve => setTimeout(resolve, ms));
        //         }

        //         const querySnapshot = await getDocs(invitacionQuery);
        //         if (querySnapshot.docs.length == 0) {
        //             return null;
        //         }

        //         // await sleep(5000);
        //         let invitacion = {} as InvitacionCollection;
        //         querySnapshot.forEach((doc) => {
        //             invitacion = {
        //                 key: doc.id,
        //                 nombre: doc.data().nombre,
        //                 code: doc.data().code,
        //                 miembros: doc.data().miembros
        //             };
        //         });

        //         setInvitacion(invitacion);
        //         setInvitados(invitacion.miembros as InvitadoCollection[]);

        //     }

        //     fetchInvitacion().catch(console.error);

        // }


    }, [notificationOpened]);

    const [isChurchSectionOpen, setIsChurchSectionOpen] = useState(false);
    const [isReceptionSectionOpen, setIsReceptionSectionOpen] = useState(false);
    const [isGiftSectionOpen, setIsGiftSectionOpen] = useState(false);
    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false);


    return (
        <>
            {contextHolder}
            <div className="grid">
                <div className="head-names grid-item0">
                    <a onClick={() => navigate(-1)} style={{ cursor: "pointer", userSelect: "none" }}>
                        <span>Angélica</span>
                        <img className='rings' src={require('../../assets/rings.png')} alt="rings" />
                        {/* <span>&</span> */}
                        <span>Cristian</span>
                    </a>
                </div>
                <div className="grid-item1" style={{ backgroundImage: `url(${foto1})` }}></div>
                <div className="grid-item2" style={{ backgroundImage: `url(${foto2})` }}></div>
                <div className="grid-item3" style={{ backgroundImage: `url(${foto3})` }}></div>
                <div className="grid-item4" style={{ backgroundImage: `url(${foto4})` }}></div>
                <div className="grid-item5" style={{ backgroundImage: `url(${foto5})` }}></div>
                <div className="grid-item6" style={{ backgroundImage: `url(${foto6})` }}></div>
            </div>



            <div className='container-buttons'>
                <Space style={{ width: "100%" }}>
                    <motion.div
                        layout
                        data-isopen={isChurchSectionOpen}
                        initial={{ borderRadius: 50 }}
                        className="parent-1"
                        onClick={() => {
                            setIsChurchSectionOpen(true)
                            setIsReceptionSectionOpen(false)
                            setIsGiftSectionOpen(false)
                            setIsConfirmationOpen(false)
                        }}>
                        <i className="fa-solid fa-church fa-fade fa-xl"></i>
                        <motion.div layout className="child">
                            <div className="child-container">
                                <div style={{ margin: "auto" }}>
                                    <span className="titulo">NUESTRA BODA</span>
                                    <span className="textAlign-justify fontSize-12">
                                        Con inmensa gratitud y emoción en nuestros corazones, deseamos que nos acompañes al momento más especial y significativo de nuestras vidas. Daremos el sí ante Dios, sellando nuestro amor y compromiso para siempre, bajo el santo sacramento del matrimonio, y esperamos con gran alegría tu presencia en nuestra boda.
                                    </span>
                                    <div className="display-grid marginTop-20">
                                        <span className="subtitulo">CÚANDO</span>
                                        <span className="fontSize-12">Junio 17, 2023 5:00 PM</span>
                                    </div>
                                    <div className="display-grid marginTop-30">
                                        <span className="subtitulo">DÓNDE</span>
                                        <span className="fontSize-12">Parroquia Nuestra Señora de Suyapa</span>
                                        <span className="fontSize-12">El Progreso, Yoro</span>
                                    </div>
                                    <div className="ver-ubicacion" style={{ margin: "10px auto" }}>
                                        <i className="fa-solid fa-location-crosshairs"></i>
                                        <a style={{ textDecoration: "none" }} href='https://goo.gl/maps/o1oVFxAWyc3u2CGn6'>
                                            VER UBICACIÓN
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                    <motion.div
                        layout
                        data-isopen={isReceptionSectionOpen}
                        initial={{ borderRadius: 50 }}
                        className="parent-2"
                        onClick={() => {
                            setIsReceptionSectionOpen(true)
                            setIsGiftSectionOpen(false)
                            setIsChurchSectionOpen(false)
                            setIsConfirmationOpen(false)
                        }}>
                        <i className="fa-solid fa-champagne-glasses fa-fade fa-xl"></i>
                        <motion.div layout className="child">
                            <div className="child-container">
                                <div style={{ margin: "auto" }}>
                                    <span className="titulo">RECEPCIÓN</span>
                                    <span className="textAlign-justify fontSize-12">
                                        Después de unir nuestras vidas en sagrado matrimonio, nos emociona enormemente invitarte a la recepción que seguirá a la ceremonia. Será un momento para celebrar, reír y disfrutar juntos, mientras compartimos nuestra felicidad recién encontrada. Tu presencia es un regalo invaluable para nosotros y estamos emocionados por compartir este día contigo!
                                    </span>
                                    <div className="display-grid marginTop-20">
                                        <span className="subtitulo">DÓNDE</span>
                                        <span className="fontSize-12">Residencial Santa Mónica</span>
                                        <span className="fontSize-12">El Progreso, Yoro</span>
                                        <span className="fontSize-12">Hora 7:00 pm</span>

                                    </div>
                                    <div className="ver-ubicacion" style={{ margin: "10px auto" }}>
                                        <i className="fa-solid fa-location-crosshairs"></i>
                                        <a style={{ textDecoration: "none" }} href='https://goo.gl/maps/wU8qttuyLLNkgg5T9'>
                                            VER UBICACIÓN
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                    <motion.div
                        layout
                        data-isopen={isGiftSectionOpen}
                        initial={{ borderRadius: 50 }}
                        className="parent-3"
                        onClick={() => {
                            setIsGiftSectionOpen(true)
                            setIsChurchSectionOpen(false)
                            setIsReceptionSectionOpen(false)
                            setIsConfirmationOpen(false)
                        }}>
                        <i className="fa-solid fa-gift fa-fade fa-xl"></i>
                        <motion.div layout className="child">
                            <div className="child-container">
                                <div style={{ margin: "auto" }}>
                                    <span className="titulo">OBSEQUIOS</span>
                                    <span className="textAlign-justify fontSize-12">
                                        ¡Nos casamos! ¡Y nos encantaría tener tu apoyo en este nuevo capítulo de nuestra vida juntos! En lugar de regalos tradicionales, si te sientes generoso/a, nos vendría bien un pequeño empujón económico para nuestros planes futuros. ¡Los abrazos son gratis, pero unos lempirillas extra nunca vienen mal!
                                        !Nuestro más sincero agradecimiento por formar parte de nuestra historia de amor!
                                    </span>
                                    <div className="display-grid fontSize-12 marginTop-20">
                                        <span className="subtitulo">NUESTRAS CUENTAS</span>
                                        <table className='cuentas'>
                                            <tbody>
                                                <tr>
                                                    <td>Cristian Euceda</td>
                                                    <td>Banpaís</td>
                                                    <td><span onClick={()=> navigator.clipboard.writeText("210550284460")}>210550284460</span></td>
                                                    {/* <td><span>210550284460</span></td> */}
                                                </tr>
                                                <tr>
                                                    <td>Cristian Euceda</td>
                                                    <td>BAC</td>
                                                    <td><span onClick={()=> navigator.clipboard.writeText("728411631")}>728411631</span></td>
                                                    {/* <td><span>728411631</span></td> */}
                                                </tr>
                                                <tr>
                                                    <td>Angelica Macedo</td>
                                                    <td>Atlántida</td>
                                                    {/* <td><span>60120259522</span></td> */}
                                                    <td><span onClick={()=> navigator.clipboard.writeText("60120259522")}>60120259522</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </motion.div>
                    </motion.div>
                    {/* <Button style={{ height: "55px", width: "55px" }} shape="circle" onClick={() => setSeccion(Seccion.Church)}>
                        <i className="fa-solid fa-church fa-xl"></i>
                    </Button> */}
                    {/* <Button style={{ height: "55px", width: "55px" }} shape="circle" onClick={() => setSeccion(Seccion.Reception)}>
                        <i className="fa-solid fa-champagne-glasses fa-xl"></i>
                    </Button> */}
                    {/* <Button style={{ height: "55px", width: "55px" }} shape="circle" onClick={() => setSeccion(Seccion.Gift)}>
                        <i className="fa-solid fa-gift fa-xl"></i>
                    </Button> */}

                    {
                        (isChurchSectionOpen === true || isReceptionSectionOpen === true || isGiftSectionOpen === true) &&
                        <Button className='btn-close-sections' size="large" icon={<i className="fa-solid fa-xmark fa-lg"></i>} shape="circle" onClick={() => {
                            setIsGiftSectionOpen(false)
                            setIsChurchSectionOpen(false)
                            setIsReceptionSectionOpen(false)
                            setIsConfirmationOpen(false)
                        }}>

                        </Button>
                    }
                    {/* <button onClick={() => {
                                position: absolute;
                                top: 10px;
                                z-index: 2;
                                left: 50%;
                                border-radius: 33px;
                                margin-left: -8px;
                                width: 20px;
                            setIsChurchSectionOpen(false)
                            setIsReceptionSectionOpen(false)
                            setIsGiftSectionOpen(false)
                            setIsConfirmationOpen(false)
                        }}>CERRAR</button> */}
                </Space>

                {/* {
                    (isChurchSectionOpen === false || isReceptionSectionOpen === false || isGiftSectionOpen === false) && */}
                <Button className='confirmar-asistencia' shape="round"
                    onClick={() => {
                        setIsConfirmationOpen(!isConfirmationOpen)
                        setIsGiftSectionOpen(false)
                        setIsChurchSectionOpen(false)
                        setIsReceptionSectionOpen(false)
                    }}>
                    {
                        !isConfirmationOpen &&
                        <motion.div initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: .5 }}>
                            <i style={{ marginRight: "8px" }} className="fa-solid fa-check"></i>
                            <span>CONFIRMAR ASISTENCIA</span>
                        </motion.div>
                    }
                    {
                        isConfirmationOpen &&
                        <motion.div initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: .5 }}>
                            <i style={{ marginRight: "8px" }} className="fa-solid fa-xmark"></i>
                            <span>CERRAR</span>
                        </motion.div>
                    }

                    {/* <span>{(isConfirmationOpen) ? "CANCELAR" : "CONFIRMAR ASISTENCIA"}</span> */}
                </Button>
                {/* } */}
                <motion.div
                    layout
                    data-isopen={isConfirmationOpen}
                    initial={{ borderRadius: 20 }}
                    className="parent-4"
                >
                    {/* <div style={{display: "grid"}}>
                        <Button type='text'
                            // style={{ position: "absolute", top: "10px", right: "10px", color: "white" }}
                            icon={<i className="fa-solid fa-circle-xmark fa-2xl" style={{ color: "white" }}></i>}
                            onClick={() => {
                                setIsConfirmationOpen(false)
                                setIsGiftSectionOpen(false)
                                setIsChurchSectionOpen(false)
                                setIsReceptionSectionOpen(false)
                            }}>
                        </Button> 
                    </div>*/}
                    <motion.div layout className="child">
                        <div className="confirmation">
                            <label className="title">CONFIRMACIÓN</label>
                            <label className="subtitle">Invitación para {invitados.length} {(invitados.length > 1) ? "personas" : "persona"}</label>
                            <Table showHeader={false} columns={columns} dataSource={invitados} pagination={false} size="small" />
                        </div>
                    </motion.div>
                </motion.div>
            </div>

        </>
    );
};

export default Invitacion;
