import { AuthProvider } from './context/AuthContext';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './views/Home/Home';
import Login from './views/Login/Login';
import Invitados from './views/Invitados/Invitados';
import Invitaciones from './views/Invitaciones/Invitaciones';
import Invitacion from './views/invitacion/Invitacion';
import './App.css';

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}>
          </Route>
          <Route path="/login/:code" element={<Login />}>
          </Route>
          <Route path="/invitados/:code" element={<Invitados />}>
          </Route>
          <Route path="/invitaciones/:code" element={<Invitaciones />}>
          </Route>
          <Route path="/invitacion/:code" element={<Invitacion />}>
          </Route>
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
