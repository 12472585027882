import fotoPortada from '../../assets/foto-1.jpg';
import './Portada.css';

const Portada = () => {
  return (
    <>
      <img className='portada' src={require('../../assets/fotos/portada-2.jpg')} alt="logo" style={{ width: "100%" }} />
      <div>
        <div className="initials" style={{ userSelect: "none" }}>
          <span>Angélica</span>
          <img className='rings' src={require('../../assets/rings.png')} alt="rings" />
          {/* <span>&</span> */}
          <span>Cristian</span>
        </div>
        <div className="fecha-boda" style={{ display: "none" }}>
          <span>Junio 17</span>
          <span style={{ margin: "auto 7px" }}>|</span>
          <span>2023</span>
        </div>
      </div>
    </>
  );
};

export default Portada;