import Portada from '../Portada/Portada';
import Contador from '../Contador/Contador';
import Mensaje from '../Mensaje/Mensaje';
import { Link, useNavigate, useParams } from 'react-router-dom';
import './Home.css';
import { decrypt } from '../../utils/security';
import { useEffect, useState } from 'react';
import { InvitacionCollection, getInvitacionByCode } from '../../firebase/invitaciones.controller';
import { Button, Input, Modal, notification } from 'antd';
import { CloseOutlined, ExclamationCircleOutlined, UnlockOutlined } from '@ant-design/icons';
import type { NotificationPlacement } from 'antd/es/notification/interface';

type NotificationType = 'success' | 'info' | 'warning' | 'error';



interface CodeData {
  encrypt: string | null;
  decrypt: string | null;
  prevState: null
}

const Home = () => {
  const navigate = useNavigate();
  const [code, setCode] = useState<string>('');
  const [loadings, setLoadings] = useState<boolean[]>([]);
  const [api, contextHolder] = notification.useNotification();
  const [isModalCodeOpen, setIsModalCodeOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');

  const openNotification = (placement: NotificationPlacement, type: NotificationType, message: string, description: string = "") => {
    api[type]({
      message: message,
      description: description,
      placement,
      duration: 5,
      icon: <ExclamationCircleOutlined style={{ color: '#faad14' }} />,
      closeIcon: <CloseOutlined style={{ fontSize: "16px" }} />,
      className: 'custom-notification',
    });
  };

  const loading = (index: number, value: boolean) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = value;
      return newLoadings;
    });
  };

  useEffect(() => {
    const url = new URL(window.location.href);
    const codeParam = url.searchParams.get('code');

    if (codeParam) {
      setCode(codeParam);
    }
  }, []);

  const verInvitacion = async (code: string) => {
    loading(0, true);
    const codeEncrypt = code;
    const codeDecrypt = decrypt(code);
    const invitacion = await getInvitacionByCode(codeDecrypt);

    if (invitacion == null) {
      loading(0, false);
      setIsModalCodeOpen(true);
      return;
    }

    navigate(`/invitacion/${codeEncrypt}`, { state: { invitacion: invitacion } });
    loading(0, false);
  }

  const handleOkModal = async () => {
    setCode(inputValue.toUpperCase());
    verInvitacion(inputValue.toUpperCase());
    setIsModalCodeOpen(false);
  }

  const handleCancelModal = () => {
    setIsModalCodeOpen(false);
  }
  return (
    <>
      {contextHolder}
      <Portada />
      <Contador />
      <Mensaje />
      <div style={{ display: "grid", background: "#fafafa" }}>
        <Button className='btn-detalle' loading={loadings[0]} onClick={() => verInvitacion(code!)}>
          <span className='titulo-boton'>Detalles</span>
          <i className="fa-solid fa-church"></i>
        </Button>
      </div>
      <Modal className='modal-code' title="Ingresa tu código" open={isModalCodeOpen} okText="Aceptar" onOk={handleOkModal} cancelText="Cancelar" onCancel={handleCancelModal} okButtonProps={{ loading: loadings[0] }}>
        <Input value={inputValue} placeholder="Código" prefix={<UnlockOutlined />} onChange={(e) => setInputValue(e.target.value)} />
      </Modal>
    </>
  );
}

export default Home;