import { useEffect, useState } from "react";
import formatTime from "../../utils/formatTime";
import { CloseOutlined } from '@ant-design/icons';
import { notification } from 'antd';
import { NotificationPlacement } from 'antd/es/notification/interface';
import './Contador.css';
import dayjs from "dayjs";

type NotificationType = 'success' | 'info' | 'warning' | 'error';


const Contador = () => {
  const [api, contextHolder] = notification.useNotification();
  const today = dayjs(new Date(new Date().getFullYear(),new Date().getMonth(),new Date().getDate()));
  const wedding = dayjs('2023-06-17');
  const weddingDate = '2023-06-17 17:00:00';
  const [endDate] = useState(weddingDate);
  const [timeLeft, setTimeLeft] = useState(formatTime(endDate));

  useEffect(() => {
    if (today.isSame(wedding)) {
      api.info({
        message: "Es Hoy! Es Hoy! Es Hoy!",
        placement: "top",
        duration: 5,
        icon: <i className="fa-solid fa-champagne-glasses" style={{ color: "#918b65" }}></i>,
        closeIcon: <CloseOutlined style={{ fontSize: "16px" }} />,
        className: 'custom-notification',
      });
    }




    // if (timeLeft.days = "00") {
    //   api.info({
    //     message: "Es Hoy! Es Hoy! Es Hoy!",
    //     placement: "top",
    //     duration: 5,
    //     icon: <i className="fa-solid fa-champagne-glasses" style={{ color: "#918b65" }}></i>,
    //     closeIcon: <CloseOutlined style={{ fontSize: "16px" }} />,
    //     className: 'custom-notification',
    //   });
    // }

    const timer = setInterval(() => {
      setTimeLeft(formatTime(endDate));
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return (
    <div style={{
      background: "#fafafa",
      height: "150px",
    }}>
      {contextHolder}
      <div style={{
        height: "150px",
        display: "flex",
        userSelect: "none"
      }}>
        {/* { timeLeft.timeLeft > 0 && */}
        <div className="counter" style={{ margin: "auto" }}>
          <div className="counter-segment">
            <span>{timeLeft.days}</span>
            <span>Días</span>
          </div>
          <span className="counter-divider">:</span>
          <div className="counter-segment">
            <span>{timeLeft.hours}</span>
            <span>Hrs</span>
          </div>
          <span className="counter-divider">:</span>
          <div className="counter-segment">
            <span>{timeLeft.minutes}</span>
            <span>Min</span>
          </div>
          <span className="counter-divider">:</span>
          <div className="counter-segment">
            <span>{timeLeft.seconds}</span>
            <span>Seg</span>
          </div>
        </div>
        {/* }
         { timeLeft.timeLeft <= 0 &&
           <div style={{ margin: "auto" }}>
             Es Hoy! Es Hoy! Es Hoy!
           </div>
        }*/}
      </div>
    </div>
  );
};

export default Contador;
